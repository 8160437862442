<template>
    <v-row no-gutters>
        <v-col
            class="mr-5"
            v-if="showAvatar"
            cols="1">
            <avatar :initialWidth="380"></avatar>
        </v-col >
        <v-col>
            <v-card
                class="bubbleBorder px-5"
                height="180"
                color="rgba(30, 101, 39, 0.88)">
                <v-row
                    no-gutters
                    class="fill-height ma-0 pa-0">
                    <v-col
                        class="px-5"
                        align="center"
                        align-self="center">
                        <span
                            :class="{ 'text-h4' : getTextBubbleText.length >= 200 }"
                            class="white--text text-h3 font-weight-medium pl-5">
                            {{getTextBubbleText}}
                        </span>
                    </v-col>
                    <v-col
                        cols="1"
                        align="center"
                        align-self="center">
                        <v-img
                            class="talk-button"
                            @click="actionOnTap()"
                            contain
                            width="140px"
                            :src="require('../assets/'+ talkButtonSrc)">
                        </v-img>
                        <span class="white--text headline">
                            {{speakingStatus}}
                        </span>

                    </v-col>
                </v-row>
            </v-card>
        </v-col>
        <v-col
            v-if="showAvatar"
            class="d-flex mt-n7"
            align="center"
            justify="center"
            cols="12">
            <v-icon size="80"
                    class="ml-10"
                    @click="$router.push('/')"
                    color="primary">
                mdi-arrow-left-circle
            </v-icon>
            <v-spacer></v-spacer>
            <span
                class="text-h3 ml-n10 font-weight-bold my-auto">
                {{pageHeader}}
            </span>
            <v-spacer></v-spacer>
        </v-col>
    </v-row>
</template>

<script>
    import avatar from '@/components/avatar';
    import {mapGetters} from 'vuex';

    export default {
        name: 'appHeader',
        components:{
            avatar
        },
        data(){
            return{
            showAvatar: false,
            pageHeader: '',
            changeFont: false,
            speakingStatus: 'Tap To Talk',
            talkButtonSrc: 'talkButton.png',
            }
        },
        computed:{
            ...mapGetters(['getTextBubbleText','getPageHeader', 'getIsSpeaking', 'speechRecognitionStatus', 'getQnaStatus'])
        },
        watch:{
            $route(to, from) {
                console.log("ROUTE TO", to.path);
                console.log("ROUTE FROM", from.path);
                if(to.path !== '/'){
                    this.showAvatar = true
                }
                else{
                    this.showAvatar = false
                }
            },
            getPageHeader(newVal){
                this.pageHeader = newVal
            },
            getIsSpeaking(newVal){
                if(newVal){
                    this.talkButtonSrc = 'stopTalking.png'
                    this.speakingStatus = 'Speaking'
                }
                if(!newVal && !this.speechRecognitionStatus){
                    this.talkButtonSrc = 'talkButton.png'
                    this.speakingStatus = 'Tap To Talk'
                }
            },
            speechRecognitionStatus(newVal){
                if(newVal){
                    this.speakingStatus = "Listening";
                    this.talkButtonSrc = 'talkButtonActive.webp'
                }

            },
            getQnaStatus(newVal){
                if(newVal){
                    this.speakingStatus = 'Thinking';
                    this.talkButtonSrc = 'talkButton.png'
                }

            }

        },
      methods:{
          actionOnTap(){
            if(this.speakingStatus === 'Speaking'){
              window.stopSpeech();
            }else{
              this.$store.commit('setSpeechRecognitionHandler', true)
            }

          }
      }


    }
</script>

<style scoped>
    .bubbleBorder {
        border-radius: 0 0 30px 30px !important;
    }
    .talk-button {

    filter: sepia(19%) saturate(697%) hue-rotate(294deg) brightness(95%) contrast(350%);
}
</style>

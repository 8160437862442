import router from "@/router";
export default {
    state:{
        showRecognitionPage: 'none',
        enableAllRecognition: false,
        enableSpeechRecognition: true,
        enableFaceDetection: true,
        speechRecognitionStatus: false,
        faceDetectionStatus: false,
        isUserApproached: false,
        isUserLooking: false,
        faceCount: 0,
        isAvatarListening: false,
        speechTranscript: '',
        speechRecognitionHandler: false,
        greetingStatus: false,
        resetKioskStatus: false,
        timeoutID: 0,
        completeUserSession: 0

    },
    getters:{
        enableAllRecognition: state => state.enableAllRecognition,
        enableSpeechRecognition: state => state.enableSpeechRecognition,
        enableFaceDetection: state => state.enableFaceDetection,
        speechRecognitionStatus: state => state.speechRecognitionStatus,
        faceDetectionStatus: state => state.faceDetectionStatus,
        isAvatarListening: state => state.isAvatarListening,
        faceCount: state => state.faceCount,
        speechTranscript: state => state.speechTranscript,
        isUserLooking: state => state.isUserLooking,
        isUserApproached: state => state.isUserApproached,
        showRecognitionPage: state => state.showRecognitionPage,
        speechRecognitionHandler: state => state.speechRecognitionHandler,
        getGreetingStatus: state => state.greetingStatus,
        getResetKioskStatus: state => state.resetKioskStatus
    },
    mutations:{
        setEnableAllRecognition(state, status){
            state.enableAllRecognition = status;
        },
        setEnableSpeechRecognition(state, status){
            state.enablespeechRecognition = status;
        },
        setEnableFaceDetection(state, status){
            state.enablefaceDetection = status;
        },
        setSpeechRecognitionStatus(state, status){
            state.speechRecognitionStatus = status
        },
        setFaceDetectionStatus(state, status){
            state.faceDetectionStatus = status
        },
        setIsAvatarListening(state, status){
            state.isAvatarListening = status;
        },
        setFaceCount(state, count){
            state.faceCount = count;
        },
        setSpeechTranscript(state, transcript){
            state.speechTranscript = transcript;
        },
        setIsUserLooking(state, status){
            state.isUserLooking = status
        },
        setIsUserApproached(state, status){
            state.isUserApproached = status
        },
        setShowRecoginitionPage(state, status){
            state.showRecognitionPage = status
        },
        setSpeechRecognitionHandler(state, status){
            state.speechRecognitionHandler = status;
        },
        setGreetingStatus(state, status){
            state.greetingStatus = status
        },
        setResetKioskStatus(state, status){
            state.resetKioskStatus = status
        },
        setTimeoutID(state, value){
            state.timeoutID = value
        }
    },
    actions:{
        resetKiosk({dispatch, commit, state}){
            clearTimeout(state.timeoutId)
            commit('setResetKioskStatus', true)
            state.timeoutId = setTimeout(function (){
                commit('setDefaultBubbleText', true)
                dispatch('avatarSpeak', 'Are you still there?')
                dispatch('openOptionsViewer', {
                    dialogHeader: 'Please Say or Tap the option you prefer',
                    dialogData: ["I'm still here","Start Over"],
                })
                state.completeUserSession = setTimeout(function(){
                    dispatch('kioskStartOver')
                }, 15000)
            }, 30000)


        },
        kioskStartOver({commit, state}){
            if(router.currentRoute.path !== '/'){
                router.push('/').then(() => {
                    console.log('All good')
                })
            }
            commit('setResetKioskStatus', false)
            commit('setGreetingStatus', false)
            console.log('Start Over Person left')
            commit('closeOptionsViewer')
            commit('closeEmailViewer')
            commit('closePdfViewer')
            commit('setTextBubbleText', 'Hi, my name is Clara, I’m here to help you. Tap the microphone button, and ask me ‘What can you do?’')
            clearTimeout(state.timeoutId)
        },
        kioskStillThere({commit, state}){
            clearTimeout(state.timeoutId)
            commit('closeOptionsViewer')
            commit('setResetKioskStatus', false)
        }
    }
  }

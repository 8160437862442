<template>
  <v-row
      justify="center"
      align="center"
      no-gutters
      class="fill-height">

    <v-col
        align="center"
        cols="10">
      <v-card class="containerBorder scrollClass">
        <v-row
            class="py-10"
            no-gutters
            align="center">
          <v-col
              cols="3"
              align="end">
                        <span class="text-h5 mr-16">
                            Search by case number:
                        </span>
          </v-col>
          <v-col cols="6">
            <v-text-field
                class="text-h5 mx-4"
                placeholder="Tap to enter case number. E.g. D-xx-xxx-xxxx-xxxxx"
                hide-details
                outlined>
            </v-text-field>
          </v-col>
          <v-col
              align="center"
              cols="3">
            <v-btn
                width="280"
                x-large
                color="primary"
                dark>
              <v-icon left>
                mdi-magnify
              </v-icon>
              Case number search
            </v-btn>
          </v-col>
        </v-row>
        <v-divider class="my-5" color="grey" width="90%"></v-divider>
        <v-row
            class="py-10"
            no-gutters
            align="center">
          <v-col
              cols="3"
              align="end">
                        <span class="text-h5 mr-16">
                            Search by name:
                        </span>
          </v-col>
          <v-col cols="6">
            <v-text-field
                class="text-h5 mx-4"
                placeholder="Tap to enter full name"
                hide-details
                outlined>
            </v-text-field>
          </v-col>
          <v-col
              align="center"
              cols="3">
            <v-btn
                width="280"
                x-large
                color="primary"
                dark>
              <v-icon left>
                mdi-magnify
              </v-icon>
              Search by name
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
      <SimpleKeyboard
          class="keyboard"
          @onKeyPress="onKeyPress"
          @onChange="onChange">
      </SimpleKeyboard>
    </v-col>
  </v-row>
</template>

<script>
import SimpleKeyboard from '@/components/SimpleKeyboard'
let config = require('./../../config/config')
export default {
  name: "todaysHearing",
  components:{
    SimpleKeyboard
  },
  methods:{
    onChange(input) {
      this.input = input;
    },
    onKeyPress(button) {
      if (button === "{enter}") {
        // TODO: send it
      }
    },
    onInputChange(input) {
      this.input = input.target.value;
    }
  },
  mounted() {
    this.$store.dispatch('openImageViewer', {
      url: config.url.clerkOffice
    })

  }
}
</script>

<style scoped>

</style>

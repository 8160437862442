<template>
  <v-app>
      <appHeader v-if="getKioskId !== undefined"></appHeader>
              <v-main>
                <v-fade-transition leave-absolute>
                  <router-view/>
                </v-fade-transition>
              </v-main>
              <pdfViewer></pdfViewer>
              <image-viewer></image-viewer>
              <email-viewer></email-viewer>
              <options-viewer></options-viewer>
              <recognition></recognition>
  </v-app>
</template>

<script>
    import appHeader from '@/components/appHeader.vue';
    import pdfViewer from '@/components/dialogs/pdfViewer.vue';
    import imageViewer from "@/components/dialogs/imageViewer";
    import emailViewer from "@/components/dialogs/emailViewer";
    import optionsViewer from "@/components/dialogs/optionsViewer";
    import recognition from '@/components/recognition.vue'
    import {mapGetters} from 'vuex';

  export default {
    name: 'App',
    components:{
      appHeader,
      pdfViewer,
      recognition,
      imageViewer,
      emailViewer,
      optionsViewer
    },
    computed:{
      ...mapGetters(['appDimensions', 'getKioskId']),
    },
  };
</script>

<style>
    html, body {
        margin: 0;
        height: 100%;
        overflow: hidden
    }
    body::-webkit-scrollbar {
        display: none;
    }
    .v-card::-webkit-scrollbar {
        -webkit-appearance: none;
        color: #6b1530;
        width: 10px;
    }
    .v-card::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background-color: rgba(0, 0, 0, .5);
        -webkit-box-shadow: 0 0 1px #6b1530;
    }
    .v-dialog{
      margin-top: 100px
    }
</style>

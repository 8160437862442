<template>
  <v-dialog
      v-model="getOptionsViewer.show"
      width="50%"
      persistent>
    <v-card class="pa-5">
      <v-card-title
          class="justify-center text-h4 pb-7 font-weight-bold">
        {{ getOptionsViewer.dialogHeader}}
      </v-card-title>
      <v-card-text align="center" class="pa-0">
        <v-card
            v-for="(item, index) in getOptionsViewer.dialogData"
            :key="index"
            class=" my-1"
            width="85%"
            @click="viewElement(item)"
            color="primary">
          <v-card-text class="white--text text-h5">
            {{item}}
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: "optionsViewer",
  computed:{
    ...mapGetters(['getOptionsViewer'])
  },
  methods: {
    viewElement(input) {
      if(input === "Start Over"){
        console.log('Start Over Person left')
        this.$store.dispatch('kioskStartOver')
      }else if(input === "I'm still here"){
        console.log('Person Still There')
        this.$store.dispatch('kioskStillThere')
      }else{
        this.$store.dispatch('requestQnA', input)
        this.$store.commit('closeOptionsViewer')
      }
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <v-dialog
      v-model="getImageViewer.show"
      width="55%"
      @click:outside="$store.commit('closeImageViewer')">
    <v-card>
      <v-card-text class="d-flex justify-end" style="overflow-y: hidden">
        <v-progress-circular
            class="mt-5"
            @click="$store.commit('closeImageViewer')"
            :rotate="180"
            :size="65"
            :width="7"
            :value="closePercentage"
            color="error">
          <v-icon large color="error">
            mdi-close
          </v-icon>
        </v-progress-circular>
      </v-card-text>
      <v-img :src="getImageViewer.url"></v-img>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: "imageViewer",
  data(){
    return{
      closePercentage: 0
    }
  },
  computed: {
    ...mapGetters(['getImageViewer', 'getIsSpeaking'])
  },
  watch:{
    getIsSpeaking(newVal){
      if(!newVal){
        const intervalObj = setInterval(()=> {this.closePercentage += 20}, 5000)
        setTimeout(() => {
          clearInterval(intervalObj);
          this.closePercentage = 0;
          console.log('What is the current path', this.$router.currentRoute.path)
          if(this.$router.currentRoute.path !== '/todayshearing'){
            this.$store.commit('closeImageViewer')
          }
        }, 3000)
      }
    }
  }
}
</script>

<style scoped>

</style>


export default {
  state:{
    contentFetched: false,
    appDimensions: {},
    pageHeader: null,
    pdfViewer: {},
    imageViewer: {},
    emailViewer: {},
    optionsViewer: {}
  },
  getters:{
    contentFetched: state => state.contentFetched,
    appDimensions: state => state.appDimensions,
    getPageHeader: state => state.pageHeader,
    getPdfViewer: state => state.pdfViewer,
    getImageViewer: state => state.imageViewer,
    getEmailViewer: state => state.emailViewer,
    getOptionsViewer: state => state.optionsViewer
  },
  mutations:{
    setAppDimensions(state){
      let newWidht = window.innerWidth;
      let newHeight = (1080 / 1920) * window.innerWidth;
      //proto: (original height / original width) x new width = new height
      // console.log(`New App Dimensions: (w X h) : (${newWidht} X ${newHeight})`);
      state.appDimensions = {
        width: newWidht,
        height: newHeight
      }
    },
    setPageHeader(state, value){
      state.pageHeader = value;
    },
    setPdfViewer(state, pdfObj){
      state.pdfViewer = pdfObj;
    },
    closePdfViewer(state){
      state.pdfViewer = {show: false}
    },
    setImageViewer(state, imgObj){
      state.imageViewer = imgObj
    },
    closeImageViewer(state){
      state.imageViewer = {show: false}
    },
    setEmailViewer(state, emailObj){
      state.emailViewer = emailObj
    },
    closeEmailViewer(state){
      state.emailViewer = {show: false}
    },
    setOptionsViewer(state, optionsObj){
      state.optionsViewer = optionsObj
    },
    closeOptionsViewer(state){
      state.optionsViewer = {show: false}
    }
  },
  actions:{
    openPdfViewer({commit}, pdfObj){
      pdfObj.show = true;
      commit('setPdfViewer', pdfObj)
    },
    openImageViewer({commit}, imgObj){
      imgObj.show = true
      commit('setImageViewer', imgObj)
    },
    openEmailViewer({commit}, emailObj){
      emailObj.show = true
      commit('setEmailViewer', emailObj)
    },
    openOptionsViewer({commit}, optionsObj){
      optionsObj.show = true
      commit('setOptionsViewer', optionsObj)
    }
  }
}

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import {auth} from './firebase/index';

Vue.config.productionTip = false






let app;

auth.onAuthStateChanged(kiosk => {
  if(!app){

    app = new Vue({
      router,
      store,
      vuetify,
      render: function (h) {
        return h(App, {props: {}})
      },
      data(){
        return{
          avatar: undefined,          
        }
      },
      watch: {
        $route(to, from){
          if(to !== '/login'){
            this.$nextTick(() => {
              const newAvatarComp = this.findAvatarComponent(this);
              const newWidth  = newAvatarComp.width - 1;
              this.$nextTick(() => {
              newAvatarComp.$el.replaceChild(this.avatar.$el.children[0], newAvatarComp.$el.children[0]);
              this.avatar = newAvatarComp;
              this.avatar.width = newWidth;
              })
            });
          }          
        }      
      },
      methods:{
        findAvatarComponent: function(parent) {
          let found = parent.$children.find(child => { return child.$options.name === "avatar"; });
          for (let i = 0; !found && i < parent.$children.length; i++) {
            found = this.findAvatarComponent(parent.$children[i]);
          }
          return found;
        },              
      },
      mounted(){        
        this.avatar = this.findAvatarComponent(this);        
      },
      beforeMount(){
        store.commit('setAppDimensions');
      }
    }).$mount('#app')
  }
  if(kiosk){
     store.dispatch('fetchKioskProfile', kiosk);
     store.dispatch('manageKioskPresence', kiosk);
  }
})


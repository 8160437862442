export default {
  state:{
    avatarLoaded: false,
    currentLanguage: 'en-US',
    avatarSpeech: '',
    textBubbleText: '',
    isSpeaking: false,
    defaultBubbleText: false
  },
  getters:{
    getCurrentLanguage: state => state.currentLanguage,
    getAvatarSpeech: state => state.avatarSpeech,
    getTextBubbleText: state => state.textBubbleText,
    getIsSpeaking: state => state.isSpeaking,
    getAvatarLoaded: state => state.avatarLoaded,
    getDefaultBubbleText: state => state.defaultBubbleText
  },
  mutations:{
    setCurrentLanguage(state, lang){
      state.currentLanguage = lang;
    },
    setIsSpeaking(state, status){
      state.isSpeaking = status;
    },
    setAvatarSpeech(state, speech){
      state.avatarSpeech = speech;
    },
    clearAvatarSpeech(state){
      state.avatarSpeech = '';
    },
    setTextBubbleText(state, text){
      state.textBubbleText = text;
    },
    clearTextBubbleText(state){
      state.textBubbleText = '';
    },
    setAvatarLoaded(state, status){
      state.avatarLoaded = status;
    },
    setDefaultBubbleText(state, status){
      state.defaultBubbleText = status
    }
  },
  actions:{
    avatarSpeak({state,commit}, speech){
      return new Promise((resolve, reject) => {
        if(speech === null || speech === '') return reject('Nothing to speak');
        commit('clearAvatarSpeech');
          setTimeout(()=>{
            commit('setAvatarSpeech', speech);
            commit('setTextBubbleText', speech);
          }, 500);
        // if(speech === state.avatarSpeech){
        //   commit('clearAvatarSpeech');
        //   setTimeout(()=>{
        //     commit('setAvatarSpeech', speech);
        //     commit('setTextBubbleText', speech);
        //   }, 500);
        // }
        // else{
        //   commit('setAvatarSpeech', speech);
        //   commit('setTextBubbleText', speech);
        // }
        resolve('Speech request sent to SitePal')
      })
    }
  }
}
